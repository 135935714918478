import React from 'react';

export default function Header(props) {
    const element = (
    <>
        <table id="tblHeader" style={{width: '100%'}}>
            <tbody> 
                <tr>
                    <td className='titleGradient grayBorderCell'>
                        <span className='pageHeader'><strong>☢ REACT Popcorn ☢</strong></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </>
    );
    return element;
}